import { template as template_5d857fa7108f4a4aa51d1ea108eae2f9 } from "@ember/template-compiler";
const FKText = template_5d857fa7108f4a4aa51d1ea108eae2f9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
